<template>
  <div class="bg-lovin h-100">
    <div class="container h-100">
      <h2 class="text-center"><router-link class="app-title" to="/">Ozzie Lovin</router-link></h2>
      <div class="h-100 row flex-column justify-content-center align-content-center">
        <div class="col-7">
          <form @submit="handleSubmit">
            <div class="form-group">
              <input type="email" placeholder="Your email" class="form-control form-input mt-2" id="email"
                     v-model="email" required/>
              <small v-if="error" id="emailHelp" class="form-text text-muted error">{{error}}</small>
            </div>
            <div class="d-flex justify-content-center">
              <button :disabled="loading" class="btn btn-secondary btn-style1">Reset</button>
            </div>
          </form>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import firebase from "firebase";

export default {
  name: "ForgotPassword",
  data() {
    return {
      email: "",
      error: "",
      loading: false
    }
  },
  methods: {
    handleSubmit(e) {
      e.preventDefault();
      let auth = firebase.auth(), that = this;
      that.loading = true;
      that.error = "";
      auth.sendPasswordResetEmail(this.email).then(function() {
        that.loading = false;
        alert("Email sent.")
      }).catch(function(error) {
        that.loading = false;
        that.error = error.message;
      });
    }
  }
}
</script>

<style scoped>

</style>
